p{
    font-size: 20px;
}

li{
    font-size: 17px;
}

.special #font, .special .mt-5, .special .mt-3, .special strong, .special li, .special span{
    font-family: sans-serif !important;
}