/* src/Components/Landing.css */
.landing {
    position: relative;
    /* height: 100vh; */
    width: 100%;
    overflow: hidden;
  }
  
  .banner-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .banner-container img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media screen and (max-width: 900px) {
    #demo{
      position: static !important;
      right: 0 !important;
    }
  }
  
  @media screen and (max-width: 1400px) {
    #head1{
      font-size: 30px !important;
    }
    #para1, #para2{
      font-size: 20px !important;
      padding-right: 20px !important;
    }
  }