@media screen and (max-width: 900px) {
    #para3 {
      font-size: 10px !important;
    }
  }
  
  .containerStyle {
    position: relative;
    text-align: center;
  }
  
  .imgStyle {
    width: 100%;
  }
  
  .textOverlayStyle {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    padding: 10px;
    text-align: center;
    font-size: 4vw; /* Responsive font size based on viewport width */
    line-height: 1.2;
  }
  
  .mainTextStyle {
    font-size: 1em; /* Responsive size */
    font-weight: 800;
  }
  
  .toyTextStyle {
    color: #FF3300;
  }
  
  .pebbleTextStyle {
    color: #186CCC;
  }
  
  .floating-text {
    display: inline-block;
    white-space: nowrap;
    animation: floatAnimation 30s linear infinite;
}

@keyframes floatAnimation {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}
